<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div
          class="li"
          @click="changePromoteIndex(index)"
          :class="{ active: promoteIndex == index }"
          v-for="(item, index) in 2"
          :key="index"
        >
          {{ index == 0 ? "我的违规" : "他人违规" }}
        </div>
      </div>
      <el-button class="ind-add-btn" v-if="promoteIndex == 0" @click="$refs.pop.open()">违规</el-button>
    </div>
    <!--rate_page_head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="promoteIndex == 0">
      <!--ind-hd start-->
      <div class="ind-hd" v-if="list.length == 0">
        <img src="../../assets/image/personage/icon6.png" />
      </div>
      <!--ind-hd end-->
      <!--ind-bd start-->
      <div class="ind-bd">
        <div class="bd-line"></div>
        <!--bd-item start-->
        <div class="bd-item" v-for="(item, index) in list" :key="index">
          <div class="circle">
            <div class="ii"></div>
          </div>
          <div class="t">{{ item.content }}</div>
          <div class="d">{{ item.createtime }}</div>
        </div>
        <!--bd-item end-->
      </div>
      <!--ind-bd end-->
      <div class="paging" v-if="list.length != 0">
        <el-pagination
          background
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!--comm-main start-->
    <div class="comm-main" v-else>
      <!--comm-bd start-->
      <div class="comm-bd">
        <!--agree-head start-->
        <div class="agree-head">
          <div class="key_page_select">
            <div class="select_item">
              <div class="label">时间:</div>
              <div class="select">
                <el-date-picker
                  @change="changeData"
                  v-model="date_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="select_item">
              <div class="label">区域:</div>
              <div class="select">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">门店:</div>
              <div class="select">
                <el-select
                  @change="changeData"
                  v-model="dian_id"
                  placeholder="请选择"
                  size="160px"
                >
                  <el-option
                    v-for="item in shopList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">职位:</div>
              <div class="select">
                <el-select
                  @input="changeData"
                  v-model="position"
                  placeholder="请选择"
                  size="160px"
                >
                  <el-option
                    v-for="item in positionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">姓名:</div>
              <div class="select">
                <el-input
                  @input="changeData"
                  v-model="name"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <!--agree-head end-->
        <!--affairs-content start-->
        <div class="affairs-content">
          <table class="affairs-table">
            <tr>
              <th>时间</th>
              <th>区域</th>
              <th>门店</th>
              <th>职位</th>
              <th>姓名</th>
              <th>工号</th>
              <th>违规内容</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{ item.createtime }}</td>
              <td>{{ item.city }}</td>
              <td>{{ item.dian_name }}</td>
              <td>{{ getPosition(item.position) }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.job_number }}</td>
              <td>{{ item.content }}</td>
            </tr>
          </table>
          <div class="paging" v-if="list.length != 0">
            <el-pagination
              background
              layout="prev, pager, next, jumper, ->, total"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!--affairs-content end-->
      </div>
      <!--comm-bd end-->
    </div>
    <!--comm-main end-->

    <!-- 借还记录未还清弹框 S -->
    <individual-pop ref="pop"></individual-pop>
    <!-- 借还记录未还清弹框 E -->
    <!--ind-main end-->
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import IndividualPop from "../../components/IndividualPop";
// import 'swiper/css/swiper.css'
export default {
  components: { IndividualPop },

  data() {
    return {
      headIndex: 0, //头部切换
      areaList: [], //
      regionList: regionData,
      CodeToText: CodeToText,
      promoteIndex: 0, //
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      date_time: "", //时间
      dian_id: "", //门店id
      position: "", //职位；传：1=区域总监2=店长3=经理4=经纪人
      name: "", //名称
      options: [],
      value: "",
      type: 2, //1 违规；2 晋升；3 获奖
      shopList: [], //门店列表
    };
  },
  methods: {
    changeHeadIndex(index) {
      this.headIndex = index;
      this.init();
    },
    changeData() {
      this.init();
    },
    init() {
      this.list = []
      this.page = 1;
      this.getweiGuiLogList();
    },
    //申请晋升
    applyPromote() {
      this.$refs.applyPromote.open();
    },
    //切换
    changePromoteIndex(index) {
      this.promoteIndex = index;
      this.init();
    },
    //选择位置
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.ruleForm.city = data.join("/");
    },
    getweiGuiLogList() {
      this.$axios
        .weiGuiLogList({
          type: 1,
          page: this.page,
          date_time: this.promoteIndex == 0 ? "" : this.date_time,
          role_type: this.promoteIndex + 1,
          name: this.promoteIndex == 0 ? "" : this.name,
          position: this.promoteIndex == 0 ? "" : this.position,
        })
        .then((res) => {
          this.list = res.data.data;
          this.total = res.data.total;
        });
    },
    getmenDian() {
      this.$axios.menDian().then((res) => {
        this.shopList = res.data;
      });
    },
  },
  created() {
    this.getmenDian();
    this.getweiGuiLogList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 10px;
  height: 64px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.ind-add-btn {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #3273f6;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
}
.ind-main {
  background: #fff;
  padding: 40px;
  .ind-hd {
    img {
      width: 300px;
      display: block;
      margin: 0 auto;
    }
  }
  .ind-bd {
    position: relative;
    margin-top: 40px;
    .bd-line {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 2px;
      background: #7ba6fe;
      height: 100%;
    }
    .bd-item {
      background: #f4f8ff;
      border-radius: 4px;
      padding: 10px 30px;
      position: relative;
      margin: 20px 0px 20px 30px;
      display: flex;
      justify-content: space-between;
      .circle {
        width: 22px;
        height: 22px;
        border-radius: 100%;
        position: absolute;
        left: -40px;
        top: 50%;
        margin-top: -6px;
        border: 1px solid #7ba6fe;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        .ii {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #7ba6fe;
        }
      }
      .d {
        width: 150px;
        text-align: right;
        font-size: 14px;
        line-height: 24px;
      }
      .t {
        width: calc(100% - 150px);
        font-size: 16px;
        color: #666;
        line-height: 24px;
      }
    }
  }
}
.comm-main {
  background: #fff;
  padding: 25px 30px;
  .comm-hd {
    width: 690px;
    height: 210px;
    background: url("../../assets/image/personage/money.png") no-repeat center;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    .c {
      font-size: 42px;
      margin-top: 15px;
    }
    .t {
      font-size: 20px;
    }
  }
  .comm-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 18px;
      padding-left: 10px;
      position: relative;
    }
    .name:before {
      display: block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0px;
      top: 50%;
      margin-top: -10px;
      content: "";
      background: #3273f6;
      border-radius: 2px;
    }
    .comm-select {
      /deep/ .el-input {
        width: 160px;
      }
      /deep/ .el-input__inner {
        border: 1px solid #ccc;
        border-radius: 0;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
    }
  }
  .comm-list {
    .comm-item {
      display: flex;
      border-bottom: 1px solid #ebebeb;
      padding: 15px 0px;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
        align-items: center;
        width: calc(100% - 150px);
        .icon {
          width: 44px;
          height: 44px;
        }
        .txt {
          margin-left: 20px;
          .t {
            font-size: 16px;
            line-height: 1.6;
          }
          .b {
            display: flex;
            font-size: 14px;
            line-height: 1.6;
            margin: 5px -20px;
          }
          .b-s {
            line-height: 1.6;
            margin: 0 20px;
          }
        }
      }
      .d {
        color: #999;
        font-size: 14px;
        margin-top: 5px;
        line-height: 1.6;
      }
      .item-r {
        width: 150px;
        text-align: right;
        .add-num {
          font-size: 24px;
          color: #ff2121;
        }
      }
    }
  }
}
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-date-editor.el-input {
    width: 200px;
  }
}
.affairs-content {
  margin: 20px 10px;
}
.affairs-table {
  width: 100%;
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}
.affairs-table th {
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-top: none;
  border-bottom: none;
  padding: 15px 10px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
.affairs-table td {
  padding: 15px 10px;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  text-align: center;
  font-size: 14px;
}
.affairs-table-btn {
  display: flex;
  .btn {
    padding: 0px;
    background: none;
    outline: none;
    margin: 5px;
    border: none;
  }
  .color-orange {
    color: #ff9600;
  }
  .color-red {
    color: #ff5151;
  }
}
.affairs-table tr td {
  background: #f7fcff;
}
.affairs-table tr:nth-child(2n) td {
  background: #fff;
}
</style>
